/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section {
  padding: 80px 0;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.active {
  opacity: 1;
  transform: translateY(0);
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

/* Navigation Styles */
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(26, 26, 26, 0.9);
  z-index: 1000;
}

.navigation ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.navigation li {
  margin: 0 10px;
}

.navigation button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 15px 10px;
  transition: color 0.3s ease;
}

.navigation li.active button {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

/* Hero Styles */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.particles-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.hero-link {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.hero-link:hover {
  color: #007bff;
}

/* About Styles */
#about {
  background-color: #f8f9fa;
}

/* Education Styles */
.vertical-timeline-element-content {
  box-shadow: 0 3px 0 #007bff;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  color: #333;
}

/* Skills Styles */
.skills-chart {
  max-width: 500px;
  margin: 0 auto 40px;
}

.skills-list h3 {
  color: #007bff;
  margin-top: 20px;
}

/* Projects Styles */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.project-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.project-card h3 {
  color: #007bff;
}

/* Internships Styles */
.internships-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.internship-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.internship-card h3 {
  color: #007bff;
}

/* Events Styles */
#events {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.event-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.event-card h3 {
  color: #007bff;
}

.event-date {
  font-style: italic;
  color: #666;
}

footer {
  background-color: #1a1a1a;
  color: white;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links a {
  color: white;
  margin-left: 15px;
  font-size: 1.2rem;
}

.chat-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* Chat Modal Styles */
.chat-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  z-index: 1001;
}

.chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.chat-modal textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.chat-modal button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}


.chat-button:hover {
  background-color: #0056b3;
}

/* Chat Modal Styles */
.chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.chat-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.chat-modal h2 {
  margin-bottom: 20px;
}

.chat-modal textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-modal button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-modal button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navigation ul {
    flex-wrap: wrap;
  }

  .navigation li {
    margin: 5px;
  }

  .navigation button {
    font-size: 14px;
    padding: 10px 5px;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-links {
    flex-direction: column;
    gap: 10px;
  }

  .section {
    padding: 60px 0;
  }

  .projects-grid,
  .internships-list,
  .events-grid {
    grid-template-columns: 1fr;
  }
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
  
  .section {
    transition: none;
  }
}
